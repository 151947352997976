<template>
  <div>
    <div class="inline">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Thông tin khách hàng'">
          <template v-slot:preview>
            <v-form ref="form" v-model="valid" lazy-validation>
              <div class="row">
                <!-- <b-form-group class="col-3">
                  <template>
                    <label>Họ:</label>
                  </template>
                  <b-form-input
                    size="sm"
                    type="text"
                    v-model="lastName"
                    required
                    placeholder="Nhập họ"
                    class="mb-3"
                  ></b-form-input>
                </b-form-group> -->
                <b-form-group class="col-3">
                  <template>
                    <label>Tên:</label>
                    <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    size="sm"
                    type="text"
                    v-model="$v.form.name.$model"
                    :state="validateState('name')"
                    required
                    placeholder="Nhập tên"
                    aria-describedby="input-live-help input-1-live-feedback"
                    class="mb-3"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-live-feedback"
                    >Vui lòng nhập tên khách hàng</b-form-invalid-feedback
                  >
                </b-form-group>
                <b-form-group class="col-4">
                  <template>
                    <label>Điện thoại:</label>
                    <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    size="sm"
                    type="number"
                    v-model="$v.form.phone.$model"
                    :state="validateState('phone')"
                    placeholder="Nhập số điện thoại"
                    class="mb-3"
                  ></b-form-input>
                  <b-form-invalid-feedback
                    v-if="!$v.form.phone.isValidPhoneNuber"
                    id="input-mobile-live-feedback"
                  >
                    Vui lòng nhập số điện thoại hợp lệ
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
              <div class="row">
                <b-form-group
                  id="input-group-1"
                  label="Email:"
                  label-for="input-1"
                  class="col-3"
                >
                  <b-form-input
                    size="sm"
                    id="input-1"
                    type="text"
                    v-model="email"
                    required
                    placeholder="Nhập email"
                  ></b-form-input>
                </b-form-group>
                <b-form-group class="col-2">
                  <template>
                    <label>Ngày sinh:</label>
                  </template>

                  <date-picker
                    placeholder="Chọn ngày sinh"
                    class="form-control form-control-sm"
                    :config="dpConfigs.date"
                    v-model="birthday"
                  ></date-picker>
                </b-form-group>
                <b-form-group class="col-2">
                  <label>Giới tính:</label>
                  <span class="text-danger">*</span>
                  <b-form-select
                    size="sm"
                    required
                    v-model="$v.form.selectedGender.$model"
                    :options="listGender"
                    class="select-style"
                    value-field="id"
                    text-field="name"
                    :state="validateState('selectedGender')"
                  >
                  </b-form-select>
                  <b-form-invalid-feedback
                    v-if="!$v.form.selectedGender.isValidselectedGender"
                    id="input-mobile-live-feedback"
                  >
                    Vui lòng chọn giới tính hợp lệ
                  </b-form-invalid-feedback>
                </b-form-group>

                <!-- <b-form-group
                  class="mb-3 col-md-3"
                  id="input-group-1"
                  label="Ngày sinh:"
                  label-for="input-1"
                >
                  <div style="display: flex">
                    <date-picker
                      placeholder="Chọn ngày sinh"
                      class="form-control form-control-sm"
                      :config="dpConfigs.date"
                      v-model="birthday"
                    ></date-picker>
                  </div>
                </b-form-group>

                <b-form-group class="col-3">
                  <div class="gender-appellation">
                    <div class="gender">
                      <template>
                        <label>Giới tính:</label>
                        <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        size="sm"
                        required
                        v-model="$v.form.selectedGender.$model"
                        :options="listGender"
                        :state="validateState('selectedGender')"
                        class="mb-3"
                        value-field="id"
                        text-field="name"
                        disabled-field="notEnabled"
                      >
                      </b-form-select>
                      <b-form-invalid-feedback id="input-live-feedback">
                        Vui lòng chọn giới tính
                      </b-form-invalid-feedback>
                    </div>
                    <div class="appellation">
                       <b-form-group
                  class="mb-3 col-md-3"
                  id="input-group-1"
                  label="Ngày sinh:"
                  label-for="input-1"
                >
                  <div style="display: flex">
                    <date-picker
                      placeholder="Chọn ngày sinh"
                      class="form-control form-control-sm"
                      :config="dpConfigs.date"
                      v-model="birthday"
                    ></date-picker>
                  </div>
                </b-form-group>
                      
                    </div>
                  </div>
                </b-form-group> -->
                <!-- <b-form-group
                  id="input-group-1"
                  label="Loại:"
                  label-for="input-1"
                  class="col-3"
                >
                  <b-form-select
                    size="sm"
                    required
                    v-model="selectedType"
                    :options="listType"
                    class="mb-3"
                    value-field="id"
                    text-field="name"
                    disabled-field="notEnabled"
                  >
                    <template v-slot:first>
                      <b-form-select-option
                        :value="null"
                        disabled
                      >-- Loại --</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Nhân viên phụ trách:"
                  label-for="input-1"
                  class="col-3"
                >
                  <Autosuggest
                    :model="searchEmployee"
                    :suggestions="filteredOptionEmployee"
                    @change="onInputChangeEmployee"
                    @select="onSelectedEmployee"
                    aria-describedby="input-src-store"
                    size="sm"
                    :limit="10"
                    placeholder="nhân viên"
                  >
                    <template #custom="{suggestion}">
                      <div>
                        <span>{{ suggestion.item.suggestionName }}</span>
                      </div>
                    </template>
                  </Autosuggest>
                </b-form-group> -->
              </div>
              <div class="row">
                <!-- <b-form-group
                  id="input-group-1"
                  label="Thành phố:"
                  label-for="input-1"
                  class="col-3"
                >
                  <b-form-select
                    size="sm"
                    required
                    v-model="selectedCity"
                    v-on:change="getDistrict"
                    :options="listCity"
                    class="mb-3"
                    value-field="id"
                    text-field="name"
                    disabled-field="notEnabled"
                  >
                    <template v-slot:first>
                      <b-form-select-option
                        :value="null"
                        disabled
                      >-- Thành phố --</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Quận:"
                  label-for="input-1"
                  class="col-3"
                >
                  <b-form-select
                    size="sm"
                    required
                    v-model="selectedDistrict"
                    :options="listDistrict"
                    class="mb-3"
                    value-field="id"
                    text-field="name"
                    disabled-field="notEnabled"
                    v-on:change="getWard"
                  >
                    <template v-slot:first>
                      <b-form-select-option
                        :value="null"
                        disabled
                      >-- Quận --</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Phường:"
                  label-for="input-1"
                  class="col-3"
                >
                  <b-form-select
                    size="sm"
                    required
                    v-model="selectWard"
                    :options="listWard"
                    class="mb-3"
                    value-field="id"
                    text-field="name"
                    disabled-field="notEnabled"
                  >
                    <template v-slot:first>
                      <b-form-select-option
                        :value="null"
                        disabled
                      >-- Phường --</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group> -->
                <b-form-group
                  id="input-group-1"
                  label="Địa chỉ:"
                  label-for="input-1"
                  class="col-3"
                >
                  <b-form-textarea
                    size="sm"
                    v-model="address"
                    placeholder="Nhập địa chỉ"
                    required
                    :rows="6"
                    :max-rows="8"
                  ></b-form-textarea>
                </b-form-group>
                <b-form-group
                  id="input-group-1"
                  label="Ghi chú:"
                  label-for="input-1"
                  class="col-4"
                >
                  <b-form-textarea
                    size="sm"
                    id="textarea1"
                    v-model="note"
                    :placeholder="'thêm ghi chú'"
                    :rows="6"
                    :max-rows="8"
                  ></b-form-textarea>
                </b-form-group>
              </div>
            </v-form>
          </template>
          <template v-slot:foot>
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              type="submit"
              @click="createCustomer"
              >Lưu</b-button
            >
            <router-link :to="'/customers'">
              <b-button
                style="margin-left: 10px; font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
                >Hủy</b-button
              >
            </router-link>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>
<style scoped>
.gender-appellation {
  display: flex;
  gap: 4px;
}

.center-label label {
  margin-bottom: 0px;
}

.form-group label {
  font-weight: 500;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import moment from 'moment';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import {
  CUSTOMER_TYPE,
  CUSTOMER_GENDER,
  CUSTOMER_APPELLATION,
} from '@/utils/constants';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
// import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import { xoa_dau } from './../../../utils/common';
import { isValidPhoneNumber } from '@/utils/validation';

export default {
  mixins: [validationMixin],
  data() {
    return {
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      valid: true,
      form: {
        name: '',
        phone: '',
        selectedGender: null,
        appellation: null,
      },
      isNew: true,
      radios: 'male',
      selectedType: null,
      listType: CUSTOMER_TYPE,
      note: '',
      birthday: '',
      address: '',
      selectedCity: null,
      listCity: [],
      selectedDistrict: null,
      listDistrict: [],
      selectWard: null,
      listWard: [],
      listGender: CUSTOMER_GENDER,
      listAppellation: CUSTOMER_APPELLATION,
      selectedEmployee: null,
      listEmployee: [],
      lastName: '',
      filteredOptionEmployee: [],
      optionEmployee: [],
      searchEmployee: '',
      email: '',
      showErrorGender: false,
      idempotenceKey: null,
    };
  },
  validations: {
    form: {
      name: { required },
      selectedGender: { required },
      phone: { required, isValidPhoneNumber },
    },
  },
  components: {
    KTCodePreview,
    datePicker,
    // Autosuggest,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Khách hàng', route: '/customers' },
      { title: 'Danh sách khách hàng', route: '/customers' },
      { title: 'Thêm khách hàng' },
    ]);
  },
  created() {
    this.fetchEmployee();
    this.fetchCity();
  },
  methods: {
    format(value) {
      this.birthday = value;
      return value;
    },
    validateState(name) {
      if (!this.isNew) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      }
    },
    createCustomer: async function () {
      this.isNew = false;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      let bd = this.birthday
        ? moment(this.birthday, 'DD/MM/YYYY').format('yyyy-MM-DD')
        : '';

      let data = {
        fullName: this.$v.form.name.$model,
        gender: this.$v.form.selectedGender.$model,
        phoneNumber: this.$v.form.phone.$model,
        email: this.email ? this.email.trim() : '',
        address: this.address,
        dateOfBirth: bd,
        note: this.note,
      };
      if (this.email) {
        data['email'] = this.email ? this.email.trim() : '';
      }
      if (bd) {
        data['dateOfBirth'] = bd;
      }
      ApiService.post('customers', data)
        .then(({ data }) => {
          this.makeToastSuccess(data.message);
          this.$router.push({
            path: '/customers',
          });
        })
        .catch(({ response }) => {
          console.log('error: ', response);
          this.makeToastFaile(response.data.message);
        });
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    fetchEmployee: async function () {
      this.optionEmployee = [];
      ApiService.setHeader();
      ApiService.get('/employees/get-list-filter').then((response) => {
        const employees = response.data.data;
        employees.map((element) => {
          let suggestionName = element.name;
          if (element.code) {
            suggestionName += ' ( ' + element.code + ')';
          }
          let nameNoneSign = xoa_dau(element.name);
          let store = {
            id: element.id,
            name: element.name,
            code: element.code,
            suggestionName: suggestionName,
            nameNoneSign: nameNoneSign,
          };
          this.optionEmployee.push(store);
        });
        this.filteredOptionEmployee = [...this.optionEmployee];
      });
    },
    fetchCity() {
      ApiService.setHeader();
      ApiService.get('city/').then((data) => {
        this.listCity = data.data.data;
      });
    },
    getDistrict() {
      this.selectedDistrict = null;
      this.selectWard = null;
      ApiService.setHeader();
      ApiService.get(`city/${this.selectedCity}/district`).then((data) => {
        this.listDistrict = data.data.data;
      });
    },
    getWard() {
      this.selectWard = null;
      ApiService.setHeader();
      ApiService.get(`city/${this.selectedDistrict}/ward`).then((data) => {
        this.listWard = data.data.data;
      });
    },
    onContext(ctx) {
      if (ctx.selectedYMD == '') {
        this.birthday = '';
      } else {
        this.birthday = ctx.selectedYMD;
      }
    },
    onSelectedEmployee(option) {
      this.searchEmployee = option.item.name;
      this.selectedEmployee = option.item.id;
    },
    onInputChangeEmployee(text) {
      if (!text) {
        text = '';
      }
      this.searchEmployee = text;

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.optionEmployee
        .filter((item) => {
          return (
            item.nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptionEmployee = [...filteredData];
    },
  },
};
</script>
